import { useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Parser } from 'html-to-react';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';

const htmlToReactParser = new Parser();

const PageHeader = ({ link, children }) => {
  const {
    contentConfiguration: { customSnippet, favicon } = {},
    displayOptions: { customSnippetsOn } = {},
    companyInfo: { name: documentTitle } = {},
  } = useContext(WebsiteSettingsContext);

  // TODO change to HELMET call
  useEffect(() => {
    if (favicon) {
      let linkEl = document.querySelector("link[rel~='icon']");
      if (!linkEl) {
        linkEl = document.createElement('link');
        linkEl.rel = 'icon';
        document.head.appendChild(linkEl);
      }
      linkEl.href = favicon;
    }
  }, [favicon]);

  return (
    <>
      <Helmet title={documentTitle} link={link}>
        {customSnippetsOn && htmlToReactParser.parse(customSnippet)}
      </Helmet>
      {children}
    </>
  );
};

export default PageHeader;
