import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm, Field } from 'react-final-form';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import { FormProvider } from '@guestyci/foundation/enums';
import Form from '@guestyci/foundation/Form';
import Divider from '@guestyci/foundation/Divider';
import FormField from '@guestyci/foundation/FormField';
import Checkbox from '@guestyci/foundation/Checkbox';
import t from '@guestyci/localize/t.macro';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import Breadcrumbs from 'components/Breadcrumbs';
import InquiryForm from 'components/InquiryForm';
import ReservationErrorDialog from 'components/ErrorDialog/ReservationErrorDialog';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import useSearchValues from 'hooks/useSearchValues';
import useSubmitInquiry from 'hooks/useSubmitInquiry';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';
import CheckOutSummaryGroupReservation from 'components/CheckOutSummary/CheckOutSummaryGroupReservation';
import { GROUP_RESERVATIONS } from 'constants/featureToggleNames';
import CheckOutSummary from 'components/CheckOutSummary';
import UpsellCarousel from 'components/UpsellCarousel';
import { useAnalytics } from 'analytics/hooks';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    background: '#fff',
  },
  contentWrapper: {
    background: '#FAFAFA',
    width: '100%',
    maxWidth: 1500,
    borderRadius: 20,
    display: 'flex',
    [create('xs')]: {
      flexWrap: 'wrap',
      padding: '10px 10px',
      justifyContent: 'center',
    },
    [create('md')]: {
      padding: '30px 30px 80px',
    },
    [create('lg')]: {
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  },
  title: {
    marginBottom: 30,
    marginTop: 30,
  },
  pci: {
    marginBottom: 30,
  },
  detailsRoot: {
    [create('lg')]: {
      maxWidth: 450,
    },
    [create('xl')]: {
      width: '100%',
      maxWidth: 600,
    },
  },
  agreements: {
    [create('xs')]: {
      width: 250,
    },
    [create('md')]: {
      width: '100%',
    },
  },
  privacyWrapper: {
    marginTop: 30,
    marginBottom: 20,
  },
  discountsWrapper: {},
}));

const Summary = ({ onChangePriceAmount, submitRef, isLoading, isInvalid }) => {
  const [, isGroupReservationEnabled] = useFeatureToggle(GROUP_RESERVATIONS);

  if (isGroupReservationEnabled) {
    return (
      <CheckOutSummaryGroupReservation
        onChangePriceAmount={onChangePriceAmount}
        submit={submitRef}
        isLoading={isLoading}
        isFormInvalid={isInvalid}
      />
    );
  }

  return (
    <CheckOutSummary
      onChangePriceAmount={onChangePriceAmount}
      submit={submitRef}
      isLoading={isLoading}
      isFormInvalid={isInvalid}
    />
  );
};

const Checkout = ({ property }) => {
  const {
    companyInfo: { name },
    userLocation,
  } = useContext(WebsiteSettingsContext);
  const [reservationTotalAmount, setReservationTotalAmount] = useState(null);
  const submitRef = useRef(null);

  const [isInvalid, setIsInvalid] = useState(true);
  const { root, contentWrapper, title, formRoot, detailsRoot, agreements, privacyWrapper, discountsWrapper } =
    useStyles();

  const { minOccupancy, pointofsale, checkInDateLocalized, checkOutDateLocalized } = useSearchValues();
  const { gtagEcommerceBeginCheckout, fbtrack } = useAnalytics();
  const { getPathWithLocale } = useGetPathToNavigate();

  const { isRequestingReservation, reservationError, setReservationError, submitHandler } = useSubmitInquiry({
    property,
    reservationTotalAmount,
  });

  const handleChangeReservationTotalAmmount = useCallback(
    (totalAmount) => {
      setReservationTotalAmount(totalAmount);
    },
    [setReservationTotalAmount]
  );

  const required = (value) => (value ? undefined : 'Required');

  useEffect(() => {
    if (!reservationTotalAmount || !property) return;

    gtagEcommerceBeginCheckout({
      listingId: property._id,
      listingName: property.title,
      totalPrice: reservationTotalAmount,
      currency: property.prices.currency,
      checkInDate: checkInDateLocalized || null,
      checkOutDate: checkOutDateLocalized || null,
      numberOfGuests: minOccupancy || null,
      pointOfSale: pointofsale,
    });
    fbtrack('InitiateCheckout', {
      listing_id: [property._id],
      content_name: property.title,
      content_type: property.propertyType,
      currency: property.prices.currency,
      value: reservationTotalAmount,
      num_guests: minOccupancy,
      check_in_date: checkInDateLocalized,
      check_out_date: checkOutDateLocalized,
      point_of_sale: pointofsale,
    });
  }, [
    reservationTotalAmount,
    property,
    checkInDateLocalized,
    minOccupancy,
    checkOutDateLocalized,
    pointofsale,
    gtagEcommerceBeginCheckout,
    fbtrack,
  ]);

  const privacyPolicyLink = getPathWithLocale('/privacy-policy');
  const termsLink = getPathWithLocale('/terms');

  const privacyPolicyAndTermsLabel = t(
    'I have read and accept the <privacy>Privacy policy</privacy> | <terms>Terms and conditions</terms>',
    {
      privacy: (chunks) => <Link to={privacyPolicyLink}>{chunks}</Link>,
      terms: (chunks) => <Link to={termsLink}>{chunks}</Link>,
    }
  );

  return (
    <div className={root}>
      <div className={contentWrapper}>
        <div className={detailsRoot}>
          <Breadcrumbs />
          <TextField variant="h1" className={title}>
            {t('Fill in your details')}
          </TextField>
          <UpsellCarousel />
          <FinalForm
            onSubmit={submitHandler}
            initialValues={{
              phone: userLocation?.countryPhoneCode,
            }}
            provider={FormProvider.Final}
            mutators={{ ...arrayMutators }}
            render={({ handleSubmit, invalid }) => {
              submitRef.current = handleSubmit;
              setIsInvalid(invalid);
              return (
                <Form
                  title="checkout"
                  onSubmit={handleSubmit}
                  provider={FormProvider.Final}
                  fieldInstance={Field}
                  className={formRoot}
                >
                  <InquiryForm />
                  <Divider />
                  <FormField className={privacyWrapper} name="privacyPolicy" type="checkbox" validate={[required]}>
                    <Checkbox>
                      <TextField className={agreements}>{privacyPolicyAndTermsLabel}</TextField>
                    </Checkbox>
                  </FormField>
                  <FormField className={discountsWrapper} name="discounts" type="checkbox">
                    <Checkbox>
                      <TextField className={agreements}>
                        {t('I am interested in receiving discounts, promotions and news about {name}', { name })}
                      </TextField>
                    </Checkbox>
                  </FormField>
                </Form>
              );
            }}
          />
        </div>
        <Summary
          submitRef={submitRef}
          isInvalid={isInvalid}
          isLoading={isRequestingReservation}
          onChangePriceAmount={handleChangeReservationTotalAmmount}
        />
      </div>
      <ReservationErrorDialog
        checkOutData={{
          property,
          checkInDateLocalized,
          checkOutDateLocalized,
        }}
        open={!!reservationError}
        error={reservationError}
        handleClose={() => setReservationError(false)}
      />
    </div>
  );
};

export default Checkout;
