import { useMutation } from 'react-query';

import createQuote from 'api/quote/createQuote';
import { QUERY_IDS } from 'api';

const { CREATE_QUOTE } = QUERY_IDS;

const useCreateQuote = ({ onError, onSuccess, params }) =>
  useMutation(createQuote, { mutationKey: [CREATE_QUOTE, params], onError, onSuccess });

const useCreateGetQuote = ({ params, onSuccess = () => {}, onError = () => {} }) => {
  const {
    isLoading: isCreatingQuote,
    isError: isCreatingQuoteError,
    isSuccess: isCreatingQuoteSuccess,
    mutate: mutateCreateQuote,
    data,
  } = useCreateQuote({
    onError,
    onSuccess,
    params,
  });

  const handleCreateQuote = () => {
    mutateCreateQuote({ params });
  };

  return {
    isLoading: isCreatingQuote,
    isError: isCreatingQuoteError,
    isSuccess: isCreatingQuoteSuccess,
    handleCreateQuote,
    data,
  };
};

export default useCreateGetQuote;
