import createStyles from '@guestyci/foundation/createStyles';

import ContactForm from 'components/ContactForm';

const useStyles = createStyles(() => ({
  root: {
    background: '#fff',
    padding: 20,
    minHeight: '100vh',
  },
}));

// TODO: discover what should be on the page
const Contact = () => {
  const { root } = useStyles();
  return (
    <div className={root}>
      <ContactForm />
    </div>
  );
};

export default Contact;
