import { useQuery } from 'react-query';

import getCities from 'api/listings/getCities';
import { QUERY_IDS } from 'api';

const { GET_CITIES } = QUERY_IDS;

const useGetCities = ({ searchValue }) => {
  return useQuery([GET_CITIES], () => getCities(), {
    select: (cities) => {
      const citiesList = cities.filter((city) => !!city.city && !!city.country);
      if (searchValue) {
        return citiesList.filter((city) => city.city.toLowerCase().includes(searchValue.toLowerCase()));
      }
      return citiesList;
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};

export default useGetCities;
