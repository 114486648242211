import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';
import { Col } from '@guestyci/foundation/Layout';

const CheckInCheckOut = ({ property = {} }) => {
  const { defaultCheckInTime, defaultCheckOutTime } = property;
  return (
    <Col className="mt-4">
      <TextField bold>{t('Check in and out')}</TextField>
      <Col>
        {defaultCheckInTime && (
          <TextField>
            {t('Check in:')}
            {defaultCheckInTime}
          </TextField>
        )}
        {defaultCheckOutTime && (
          <TextField>
            {t('Check out:')}
            {defaultCheckOutTime}
          </TextField>)}
      </Col>
    </Col>
  )
}

export default CheckInCheckOut;