import { useQuery } from 'react-query';

import getCurrencyRate from 'api/currency/currencyRate';
import { QUERY_IDS } from 'api';

const { GET_CURRENCY_RATE } = QUERY_IDS;

const useGetCurrencyRate = ({ onSuccessHandler, from, to }) => {
  const { refetch, data, isFetching, isLoading, isSuccess, isError } = useQuery(
    [`${GET_CURRENCY_RATE}-FROM-${from}-TO-${to}`],
    () => getCurrencyRate({ from, to }),
    {
      onSuccess: onSuccessHandler,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  return {
    fetchHandler: refetch,
    data,
    isFetching,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useGetCurrencyRate;
