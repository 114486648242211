/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useReducer, useEffect, useContext } from 'react';
import { useMutation } from 'react-query';
import cn from 'classnames';
import { flatten, uniqBy } from 'lodash';

import Checkbox from '@guestyci/foundation/Checkbox';
import { Col, Row } from '@guestyci/foundation/Layout';
import createStyles from '@guestyci/foundation/createStyles';
import Accordion from '@guestyci/foundation/Accordion';
import Input from '@guestyci/foundation/Input';
import StatusIndication from '@guestyci/foundation/StatusIndication';
import Spinner from '@guestyci/foundation/Spinner';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro/t.macro';

import CouponCode from 'components/CouponCode';
import useDio from 'hooks/useDio';
import { DISCOUNT_TYPE, ACCOUNT_TYPES } from 'constants/constants';
import addCouponGroup from 'api/quote/addCouponGroup';
import addCoupon from 'api/quote/addCoupon';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';

const useStyles = createStyles(({ palette, spacer, breakpoints: { create } }) => ({
  checkbox: {
    marginLeft: -10,
    '& svg[class*="Checkbox-checked"]': {
      fill: '#1BBCEF',
    },
  },
  couponInputContainer: {
    [create('xs')]: {
      width: '95%',
    },
    [create('xl')]: {
      width: 205,
    },
  },
  couponInput: {
    marginBottom: 10,
    marginRight: 5,
  },
  addButton: {
    '&.btn': {
      fontSize: 14,
      paddingLeft: spacer(2),
      paddingRight: spacer(2),
      color: palette.primary.default,

      '&:hover': {
        color: palette.primary.default,
      },
    },
  },
  accordion: {
    paddingTop: 5,
    paddingBottom: 15,
    '&  button[class*="Accordion-header"]': {
      display: 'none',
    },
    '& div[class*="Accordion-content"]': {
      paddingTop: 5,
    },
  },
  closeIcon: {
    marginRight: 15,
    cursor: 'pointer',
  },
  couponWrapper: {
    padding: 13,
  },
}));

const COUPON_ACTIONS = {
  ADD_COUPON: 'ADD_COUPON',
  REMOVE_COUPON: 'REMOVE_COUPON',
};

function reducer(state, action) {
  switch (action.type) {
    case COUPON_ACTIONS.ADD_COUPON:
      return action.payload;
    case COUPON_ACTIONS.REMOVE_COUPON:
      return null;
    default:
      return state;
  }
}

const useAddCoupon = (handleCoupon) => {
  return useMutation(handleCoupon);
};

const uniqCoupon = (data) => {
  const allCoupons = flatten(data?.map((el) => el.coupons));
  return uniqBy(allCoupons, 'couponId');
};

const Coupon = ({
  quoteId,
  getUpdatedQuote,
  actualDiscount,
  hostPayout,
  isGroupReservation = false,
  setIsCouponApplied,
}) => {
  const { dioTrack } = useDio();
  const { checkbox, couponInput, couponInputContainer, addButton, accordion } = useStyles();
  const [haveCoupon, setHaveCoupon] = useState(false);
  const [coupon, setCoupon] = useState();
  const [showDiscountExceedMsg, setShowDiscountExceedMsg] = useState(false);
  const [couponError, setCouponError] = useState(null);
  const [appliedCoupon, dispatch] = useReducer(reducer, null);
  const { flags: { accountType = ACCOUNT_TYPES.PRO } = {} } = useContext(WebsiteSettingsContext);
  const hideCoupons = accountType === ACCOUNT_TYPES.LITE;

  const handleCoupon = isGroupReservation ? addCouponGroup : addCoupon;
  const { mutateAsync: handleCouponRequest, isLoading: isAddingCoupon } = useAddCoupon(handleCoupon);
  const { FIXED } = DISCOUNT_TYPE;

  useEffect(() => {
    const { discountType, discount } = appliedCoupon || {};
    const showDiscountMessage = discountType?.toUpperCase() === FIXED && discount > hostPayout;
    setShowDiscountExceedMsg(showDiscountMessage);
  }, [appliedCoupon, hostPayout, FIXED]);

  const couponIsInvalid = t('Coupon code is invalid. Please check the code and try again');
  const couldNotRemoveCoupon = t('Could not remove coupon. Please try again later');

  const handleCouponError = ({ message = null } = {}) => {
    setCouponError(message || couponIsInvalid);
    dioTrack('click_got_coupon', 'click', {
      coupon,
      isApplied: false,
    });
  };

  if (!quoteId) return null;

  const applyGroupCoupon = async () => {
    if (coupon) {
      try {
        const data = await handleCouponRequest({ quoteId, coupon });
        if (data?.length) {
          setCouponError(null);
          dispatch({ type: COUPON_ACTIONS.ADD_COUPON, payload: uniqCoupon(data)[0] });
          dioTrack('click_got_coupon', 'click', {
            coupon,
            isApplied: true,
          });
          setCoupon('');
          setIsCouponApplied(true);
          await getUpdatedQuote({ quoteId });
        } else {
          handleCouponError();
        }
      } catch (error) {
        handleCouponError();
      }
    }
  };

  const applyCoupon = async () => {
    if (coupon) {
      try {
        const data = await handleCouponRequest({ quoteId, coupon });
        if (data.length && data[0].couponCode === coupon.toUpperCase()) {
          setCouponError(null);
          dispatch({ type: COUPON_ACTIONS.ADD_COUPON, payload: data[0] });
          dioTrack('click_got_coupon', 'click', {
            coupon,
            isApplied: true,
          });
          setCoupon('');
          await getUpdatedQuote({ quoteId });
          setIsCouponApplied(true);
        } else {
          handleCouponError();
        }
      } catch (error) {
        handleCouponError();
      }
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setCoupon(value?.toUpperCase());
  };

  const removeCoupon = async () => {
    try {
      await handleCouponRequest({ quoteId });
      dispatch({ type: COUPON_ACTIONS.REMOVE_COUPON });
      setCoupon('');
      await getUpdatedQuote({ quoteId });
      setIsCouponApplied(false);
    } catch (error) {
      handleCouponError({ message: couldNotRemoveCoupon });
    }
  };

  const removeGroupCoupon = async () => {
    try {
      await handleCouponRequest({ quoteId, coupon: '' });
      dispatch({ type: COUPON_ACTIONS.REMOVE_COUPON });
      setCoupon('');
      await getUpdatedQuote({ quoteId });
      setIsCouponApplied(false);
    } catch (err) {
      handleCouponError({ message: couldNotRemoveCoupon });
    }
  };

  const handleApplyCoupon = isGroupReservation ? applyGroupCoupon : applyCoupon;
  const handleRemoveCoupon = isGroupReservation ? removeGroupCoupon : removeCoupon;

  if (!quoteId || hideCoupons) return null;

  return (
    <Col>
      {!appliedCoupon && (
        <Checkbox
          data-qa="coupon-checkbox"
          className={checkbox}
          checked={haveCoupon}
          onChange={() => setHaveCoupon((prev) => !prev)}
        >
          {t('I have a coupon')}
        </Checkbox>
      )}
      <Accordion className={accordion} open={haveCoupon}>
        {!appliedCoupon && (
          <Row justify="start">
            <Col className={couponInputContainer}>
              <Input
                data-qa="coupon-input"
                className={couponInput}
                value={coupon}
                onChange={handleInputChange}
                disabled={isAddingCoupon || appliedCoupon}
              />
              <TextField color="secondary">{t('You can use only one code per reservation')}</TextField>
              {couponError && <TextField color="error">{couponError}</TextField>}
            </Col>
            <button
              data-qa="coupon-submit"
              type="button"
              className={cn(addButton, 'btn btn-flat font-weight-bold')}
              onClick={handleApplyCoupon}
              disabled={isAddingCoupon}
            >
              {isAddingCoupon ? <Spinner /> : t('Apply')}
            </button>
          </Row>
        )}
        {appliedCoupon && (
          <CouponCode coupon={appliedCoupon} actualDiscount={actualDiscount} handleClick={handleRemoveCoupon} />
        )}
        {showDiscountExceedMsg && (
          <Row>
            <Col>
              <StatusIndication
                variant="warning"
                text={t(
                  "The coupon value is higher than the booking fee. You won't be able to use the remaining balance."
                )}
              />
            </Col>
          </Row>
        )}
      </Accordion>
    </Col>
  );
};

export default Coupon;
