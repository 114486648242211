import { useContext } from 'react';

// eslint-disable-next-line import/no-cycle
import { AnalyticsContext } from '../AnalyticsProvider';

const useAnalytics = () => {
  return useContext(AnalyticsContext);
};

export default useAnalytics;
