import { Redirect } from 'react-router-dom';

import Checkout from 'pages/CheckoutPage';
import HomePage from 'pages/HomePage';
import InquirySuccessPage from 'pages/InquirySuccessPage';
import InstantSuccessPage from 'pages/InstantSuccessPage';
import PropertyPage from 'pages/PropertyPage';
import SearchPage from 'pages/SearchPage';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Contact from 'pages/Contact';
import Terms from 'pages/Terms';
import { shortLanguageList } from 'constants/languages';

export const allowedLocales = shortLanguageList.join('|');

const render = (props, Component, isRedirect) => {
  if (isRedirect) {
    return Component;
  }
  return shortLanguageList.includes(props.match.params.locale) ? (
    Component
  ) : (
    <Redirect
      to={{
        pathname: `/en${props.match.url}`,
        search: props.location.search,
      }}
    />
  );
};

export const routesWithLocale = [
  {
    name: 'Home page',
    exact: true,
    path: `/:locale(${allowedLocales})?`,
    page: <HomePage />,
    render: (props) => render(props, <HomePage />),
  },
  {
    name: 'Search page',
    path: `/:locale(${allowedLocales})?/properties`,
    exact: true,
    page: <SearchPage />,
    render: (props) => render(props, <SearchPage />),
  },
  {
    name: 'Property page',
    path: `/:locale(${allowedLocales})?/properties/:id`,
    exact: true,
    page: <PropertyPage />,
    render: (props) => render(props, <PropertyPage />),
  },
  {
    name: 'Checkout page',
    path: `/:locale(${allowedLocales})?/properties/:id/checkout`,
    exact: true,
    page: <Checkout />,
    render: (props) => render(props, <Checkout />),
  },
  {
    name: 'Inquiry success page',
    path: `/:locale(${allowedLocales})?/inquiry-success`,
    page: <InquirySuccessPage />,
    render: (props) => render(props, <InquirySuccessPage />),
  },
  {
    name: 'Instant success page',
    path: `/:locale(${allowedLocales})?/instant-success`,
    page: <InstantSuccessPage />,
    render: (props) => render(props, <InstantSuccessPage />),
  },
  {
    name: 'Privacy policy page',
    path: `/:locale(${allowedLocales})?/privacy-policy`,
    page: <PrivacyPolicy />,
    render: (props) => render(props, <PrivacyPolicy />),
  },
  {
    name: 'Terms page',
    path: `/:locale(${allowedLocales})?/terms`,
    page: <Terms />,
    render: (props) => render(props, <Terms />),
  },
  {
    name: 'Contact page',
    path: `/:locale(${allowedLocales})?/contact`,
    page: <Contact />,
    render: (props) => render(props, <Contact />),
  },
  {
    name: 'Redirect',
    path: '*',
    page: <Redirect to="/en" />,
    render: (props) => render(props, <Redirect to="/en" />, true),
  },
];

const routes = [
  {
    name: 'Home page',
    exact: true,
    path: `/`,
    page: <HomePage />,
  },
  {
    name: 'Search page',
    exact: true,
    path: `/properties`,
    page: <SearchPage />,
  },
  {
    name: 'Property page',
    exact: true,
    path: `/properties/:id`,
    page: <PropertyPage />,
  },
  {
    name: 'Checkout page',
    exact: true,
    path: `/properties/:id/checkout`,
    page: <Checkout />,
  },
  {
    name: 'Inquiry success page',
    path: `/inquiry-success`,
    page: <InquirySuccessPage />,
  },
  {
    name: 'Instant success page',
    path: `/instant-success`,
    page: <InstantSuccessPage />,
  },
  {
    name: 'Privacy policy page',
    path: `/privacy-policy`,
    page: <PrivacyPolicy />,
  },
  {
    name: 'Terms page',
    path: `/terms`,
    page: <Terms />,
  },
  {
    name: 'Contact page',
    path: `/contact`,
    page: <Contact />,
  },
  {
    name: 'Redirect',
    path: '*',
    page: <Redirect to="/" />,
  },
];

export default routes;
