import { useContext } from 'react';

import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';

const BankTransferInformation = () => {
  const {
    contentConfiguration: { bankPayment },
  } = useContext(WebsiteSettingsContext);

  return (
    <div className="mt-5 mb-5">
      <TextField varian="h5" color="secondary">
        {bankPayment?.description ||
          t(
            'If you choose this payment option, the accommodation will be pre-reserved for you. However, to confirm the booking you will have to make a bank transfer deposit. The payment instruction will be sent to you by email at the end of the booking process.'
          )}
      </TextField>
    </div>
  );
};

export default BankTransferInformation;
