import { useQuery } from 'react-query';

import getWebsiteSettings from 'api/settings/getWebsiteSettings';
import { QUERY_IDS } from 'api';

const { GET_WEBSITE_SETTINGS } = QUERY_IDS;

const useGetWebsiteSettings = ({ language, enabled }) => {
  const { data, isFetching, isLoading, isSuccess, isError } = useQuery(
    [GET_WEBSITE_SETTINGS, language],
    () => getWebsiteSettings({ language }),
    {
      refetchOnWindowFocus: false,
      enabled,
    }
  );

  return {
    data,
    isFetching,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useGetWebsiteSettings;
