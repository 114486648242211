import t from '@guestyci/localize/t.macro';

import { getMealPlansTranslations, MEAL_PLANS } from 'constants/constants';

/**
 *  Returns translated title and description of Meal Plan based on an incoming list of meal plans.
 * @param {Object} params
 * @param {Array<['breakfast', 'lunch', 'dinner', 'all_inclusive']>} params.mealPlans List of meal plans
 * @returns {{title: string, description: string, isAccordion: boolean}} Title, description and should accordion be enabled
 */
const useMealPlan = ({ mealPlans }) => {
  const MEAL_PLANS_TRANSLATIONS = getMealPlansTranslations();

  // Show Full board if meal plans have all 3 options (Breakfast, dinner, lunch) or all_inclusive option
  if (mealPlans.includes(MEAL_PLANS.ALL_INCLUSIVE) || mealPlans.length === 3) {
    return {
      isAccordion: true,
      title: t('Full board included'),
      description: t('Breakfast & lunch & dinner included'),
    };
  }

  // Show Half board if meal plans have only 2 options (xor of 2 for Breakfast, dinner, lunch)
  if (mealPlans.length === 2) {
    const meals = mealPlans.map((meal) => MEAL_PLANS_TRANSLATIONS[meal]).join(' & ');
    return {
      isAccordion: true,
      title: t('Half board included'),
      description: t('{meals} included', {
        meals,
      }),
    };
  }

  // Show text instead of accordion with only one option of Breakfast or dinner or lunch
  const mealCode = mealPlans[0];
  return {
    isAccordion: false,
    title: t('{meal} included', {
      meal: MEAL_PLANS_TRANSLATIONS[mealCode],
    }),
  };
};

export default useMealPlan;
