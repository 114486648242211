import { useState, useContext, useCallback } from 'react';
import { useQuery } from 'react-query';
import { ReactSession } from 'react-client-session';
import { isMobile } from 'react-device-detect';

import Dio from '@guestyci/dio';
import Resource from '@guestyci/agni';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import getUserLocation from 'api/location/getUserLocation';
import { QUERY_IDS } from 'api';

const { GET_USER_LOCATION } = QUERY_IDS;

const { config, env } = Resource.create({
  domain: '/',
  env: process.env.REACT_APP_ENV_CLUSTER,
  development: 'localhost',
});

const mock = {
  track: (key, type, target) => console.table({ key, type, target }),
};

const dio = {};

const useDio = () => {
  const [dioInited, setDioInited] = useState(false);

  const { data: userLocation } = useQuery(GET_USER_LOCATION, () => getUserLocation(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { accountData: { accountId } = {}, companyInfo: { name } = {} } = useContext(WebsiteSettingsContext);

  const initDio = () => {
    if (dioInited) return;
    dio.bookingEngine =
      env === 'production'
        ? Dio.init({
            context: 'booking_engine',
            baseUrl: config.API_GATEWAY,
            isPublic: true,
          })
        : mock;
    setDioInited(true);
  };

  const dioTrack = useCallback(
    (eventName, eventType, properties = {}) => {
      dio.bookingEngine.track(eventName, eventType, {
        ...properties,
        is_mobile: isMobile,
        website_name: name,
        user_session: ReactSession.get('user_session'),
        localStorage_id: localStorage.getItem('user_id'),
        account_id: accountId,
        website_url: window.location.origin,
        guest_origin: document.referrer,
        user_location: {
          ip: userLocation?.IPv4,
        },
      });
    },
    [accountId, name, userLocation]
  );

  return {
    initDio,
    dioTrack,
    dioInited,
  };
};

export default useDio;
