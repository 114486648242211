import { useState, useLayoutEffect, useEffect, useCallback } from 'react';

const useIsOverflow = (ref, items) => {
  const [isOverflow, setIsOverflow] = useState(false);

  const trigger = useCallback((current) => {
    const hasOverflow =
      current.scrollHeight > current.clientHeight + 10 || current.scrollWidth > current.clientWidth + 10;

    setIsOverflow(hasOverflow);
  }, []);

  useEffect(() => {
    if (ref?.current) trigger(ref?.current);
    function handleWindowSizeChange() {
      if (ref?.current) trigger(ref?.current);
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [ref, trigger, items]);

  useLayoutEffect(() => {
    if (ref?.current) {
      trigger(ref?.current);
    }
  }, [ref, trigger, items]);

  return isOverflow;
};

export default useIsOverflow;
