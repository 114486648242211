import Spinner from '@guestyci/foundation/Spinner';
import createStyles from '@guestyci/foundation/createStyles';

const useStyles = createStyles(() => ({
  root: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

const FullPageLoader = () => {
  const { root } = useStyles();
  return (
    <div className={root}>
      <Spinner />
    </div>
  );
};

export default FullPageLoader;
