
import TextField from "@guestyci/foundation/TextField";
import { Row } from "@guestyci/foundation/Layout";
import t from "@guestyci/localize/t.macro";

import HouseRules from "components/HouseRules";

const HouseRulesSection = ({ property = {} }) => {
  const { _id, unitTypeHouseRules: { houseRules = null } = {} } = property;
  if (!houseRules) return null;

  return (
    <div>
      <TextField bold className="mb-2">
        {t('House rules')}
      </TextField>
      <Row>
        <HouseRules unitTypeHouseRules={property.unitTypeHouseRules} listingId={_id} />
      </Row>
    </div>
  );
}

export default HouseRulesSection;