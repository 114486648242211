import { useState, useMemo } from 'react';
import moment from 'moment';

import DateRangePicker from '@guestyci/foundation/DatePicker/DateRangePicker';
import t from '@guestyci/localize/t.macro';

import useGetCalendarAvailability from 'hooks/useGetCalendarAvailability';
import useSearchValues from 'hooks/useSearchValues';
import AvailabilityHelper from 'utils/calendar-availability.util';
import { DATE_FORMAT_MONTH } from 'constants/date';

const DateRandePickerAvailability = ({ className, dateRange, setDateRange }) => {
  const { listingId } = useSearchValues();
  const [focusedField, setFocusedField] = useState();
  const [currentMonth, setCurrentMonth] = useState(moment());
  const initialVisibleMonth = currentMonth.format(DATE_FORMAT_MONTH);
  const { data: calendar } = useGetCalendarAvailability({ listingId });

  const availabilityHelper = useMemo(() => {
    return new AvailabilityHelper({ calendar });
  }, [calendar]);

  const blockedDates = useMemo(() => {
    const { startDate: checkInDate, endDate: checkOutDate } = dateRange ?? {};
    if (focusedField === 'endDate') {
      return availabilityHelper.getCheckOutDisabledDays({
        currentMonth,
        checkInDate,
      });
    }
    // gag that allows to select "checkIng then checkOut" and "checkOut then checkIn"
    // note: date picker could clear checkOut when checkIn is selected
    return availabilityHelper.getCheckInDisabledDays({
      currentMonth,
      checkOutDate: checkInDate ? undefined : checkOutDate,
    });
  }, [availabilityHelper, focusedField, currentMonth, dateRange]);

  const onChange = (value) => {
    // onDatesChange(value);
    setDateRange(value);
  };

  const onMonthChange = (date) => {
    setCurrentMonth(date);
  };

  return (
    <DateRangePicker
      blockedDates={blockedDates}
      className={className}
      value={dateRange}
      onFocusChange={(activeMonth) => setFocusedField(activeMonth)}
      onChange={(value) => onChange(value)}
      onMonthChange={(value) => onMonthChange(value)}
      initialVisibleMonth={initialVisibleMonth}
      horizontalMargin={75}
      startDatePlaceholderText={t('Start date')}
      endDatePlaceholderText={t('End date')}
    />
  );
};

export default DateRandePickerAvailability;
