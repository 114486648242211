import { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import cn from 'classnames';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import countryList from 'react-select-country-list';

import FormField from '@guestyci/foundation/FormField';
import Input from '@guestyci/foundation/Input';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import TextField from '@guestyci/foundation/TextField';
import { Row, Col } from '@guestyci/foundation/Layout';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import createStyles from '@guestyci/foundation/createStyles';
import t from '@guestyci/localize/t.macro/t.macro';

import Icon from 'components/Icon';
import ZeroAmountNotice from 'components/ZeroAmountNotice';
import { ZERO_AMOUNT_NOTIFICATION } from 'constants/featureToggleNames';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    [create('xs')]: {
      width: '100%',
    },
    [create('xl')]: {
      width: 560,
    },
    '& .form-field': {
      '& div[class*="Input-root"], div[class*="Input-root"], div[class*="Dropdown-root"], div[class*="dialCodeInput"]':
        {
          backgroundColor: '#fff',
        },
    },
  },
  title: {
    marginBottom: 20,
    marginTop: 30,
  },
  smallInput: {
    [create('xs')]: {
      width: '100%',
      marginBottom: 10,
    },
    [create('xl')]: {
      width: 124,
      marginBottom: 0,
    },
  },
  bigInput: {
    [create('xs')]: {
      width: '100%',
      marginBottom: 10,
    },
    [create('xl')]: {
      width: 269,
      marginBottom: 0,
    },
  },
  creditCard: {
    flexWrap: 'wrap',
    [create('xs')]: {
      width: '100%',
      marginBottom: 10,
    },
    [create('xl')]: {
      marginBottom: 30,
    },
  },
  creditCardWrapper: {
    border: '1px solid #D4D8E1',
    borderRadius: 6,
    padding: '0px 10px',
    marginRight: 5,
    overflow: 'hidden',
    height: 27,
  },
  amex: {
    '& img': {
      transform: 'scale(2.68,2.8)',
    },
  },
  discover: {
    '& img': {
      transform: 'scale(2.4,2.1)',
    },
  },
  twoInRow: {
    marginBottom: 20,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > div': {
      [create('xs')]: {
        width: '100%',
        marginBottom: 10,
      },
      [create('xl')]: {
        width: 269,
        marginBottom: 0,
      },
    },
  },
  threeInRow: {
    marginBottom: 15,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > div': {
      [create('xs')]: {
        width: '100%',
        marginBottom: 10,
      },
      [create('xl')]: {
        width: 173,
        marginBottom: 0,
      },
    },
  },
  supportedCards: {
    [create('xs')]: {
      display: 'none',
    },
    [create('md')]: {
      display: 'flex',
    },
  },
  simpleInput: {
    height: 46,
    border: '1px solid #D4D8E1',
    borderRadius: 2,
    padding: '0px 15px',
    background: '#fff',
  },
  paymentSteps: {
    paddingLeft: 15,
    marginBottom: 10,
  },
  paymentStepsWrapper: {
    marginBottom: 30,
  },
}));

const cardElementOptions = {
  style: {
    base: {
      lineHeight: '43px',
      padding: '10px',
      backgroundColor: '#fff',
      fontSize: '14px',
    },
    invalid: {
      color: '#fa755a',
      fontSize: '14px',
    },
  },
};

const stripeValidate = (value) => (value?.complete ? undefined : 'Required');

const PaymentDetails = ({ showBilling, showCardHolderName }) => {
  const [, isZeroAmountNotificationEnabled] = useFeatureToggle(ZERO_AMOUNT_NOTIFICATION);
  const {
    root,
    title,
    bigInput,
    smallInput,
    creditCard,
    creditCardWrapper,
    amex,
    discover,
    twoInRow,
    threeInRow,
    supportedCards,
    simpleInput,
  } = useStyles();
  const options = useMemo(() => countryList().getData(), []);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const handleSearch = debounce((e) => {
    const { value } = e.target;
    const filtered = options.filter(({ label }) => label.toLowerCase().includes(value.toLowerCase()));
    setFilteredOptions(filtered);
  }, 250);
  return (
    <div className={root}>
      {isZeroAmountNotificationEnabled && <ZeroAmountNotice />}
      <TextField variant="h3" className={title}>
        {t('Payment details')}
      </TextField>
      <Row className="mb-4">
        <TextField>{t('Cards accepted')}</TextField>
        <Row className={cn(supportedCards, 'ml-xl-2')}>
          <span className={creditCardWrapper}>
            <Icon icon="visa" size={25} />
          </span>
          <span className={cn(amex, creditCardWrapper)}>
            <Icon icon="amex" size={25} />
          </span>
          <span className={creditCardWrapper}>
            <Icon icon="unionpay" size={25} />
          </span>
          <span className={cn(discover, creditCardWrapper)}>
            <Icon icon="discover" size={25} />
          </span>
          <span className={creditCardWrapper}>
            <Icon icon="diners" size={25} />
          </span>
          <span className={creditCardWrapper}>
            <Icon icon="jcb" size={25} />
          </span>
          <span className={creditCardWrapper}>
            <Icon icon="mastercard" size={25} />
          </span>
        </Row>
      </Row>
      <Row justify="between" className={creditCard}>
        <FormField
          className={cn(bigInput, 'form-field')}
          label={t('Credit card number')}
          name="cardNumber"
          validate={[stripeValidate]}
        >
          <CardNumberElement className={simpleInput} options={cardElementOptions} />
        </FormField>
        <FormField
          className={cn(smallInput, 'form-field')}
          label={t('Expiration date')}
          name="expirationDate"
          validate={[stripeValidate]}
        >
          <CardExpiryElement className={simpleInput} options={cardElementOptions} />
        </FormField>
        <FormField validate={[stripeValidate]} className={cn(smallInput, 'form-field')} label={t('CVV')} name="CVV">
          <CardCvcElement className={simpleInput} options={cardElementOptions} />
        </FormField>
      </Row>
      <Col className="mb-4">
        {showCardHolderName && (
          <Row>
            <FormField
              className={cn(bigInput, 'form-field mr-xl-4')}
              label={t('First name')}
              name="cardHolderFirstName"
              required
            >
              <Input placeholder={t('Card holder first name')} />
            </FormField>
            <FormField className={cn(bigInput, 'form-field')} label={t('Last name')} name="cardHolderLastName" required>
              <Input placeholder={t('Card holder last name')} />
            </FormField>
          </Row>
        )}
      </Col>
      <Col>
        <Col className="mb-xl-4">
          <TextField variant="h3">{t('Billing address')}</TextField>
        </Col>
        {showBilling && (
          <Col>
            <Row className={twoInRow}>
              <FormField className="form-field" label={t('Street')} name="billingAddressStreet">
                <Input placeholder={t('Street name and number')} />
              </FormField>
              <FormField className="form-field" label={t('City')} name="billingAddressCity">
                <Input />
              </FormField>
            </Row>
            <Row className={threeInRow}>
              <FormField className="form-field" label={t('State')} name="billingAddressState">
                <Input />
              </FormField>
              <FormField className="form-field" label={t('Zip code')} name="billingAddressZipCode">
                <Input />
              </FormField>
              <FormField className="form-field" label={t('Country')} name="billingAddressCountry">
                <Dropdown searchable onSearchQueryChange={handleSearch}>
                  {filteredOptions.map(({ label, value }) => (
                    <Option value={value} key={label}>
                      {label}
                    </Option>
                  ))}
                </Dropdown>
              </FormField>
            </Row>
          </Col>
        )}
      </Col>
    </div>
  );
};

export default PaymentDetails;
