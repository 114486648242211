import createStyles from '@guestyci/foundation/createStyles';

import PropertyRate from 'components/PropertyRate';

const useStyles = createStyles(({
  breakpoints: { create }
}) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 0 20px',
    [create('md')]: {
      padding: '13px 0 0 0',
    },
  }
}));

const Reviews = ({ property = {} }) => {
  const { root } = useStyles();
  const { reviews: { total = 0 } = {} } = property;
  if (total <= 0) return null;
  return (
    <div className={root}>
      <PropertyRate inline property={property} />
    </div>
  );
}

export default Reviews;