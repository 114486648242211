import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';

import DescriptionSection from './DescriptionSection';

const useStyles = createStyles(() => ({
  descriptionText: {
    marginTop: 30,
    whiteSpace: 'pre-wrap',
  },
}));


const Details = ({ property = {} }) => {
  const { descriptionText } = useStyles();
  if (!property.publicDescription) return null;
  const {
    publicDescription: {
      access = null,
      neighborhood = null,
      interactionWithGuests = null,
      notes = null,
      space = null,
      summary = null,
      transit = null,
    } = {},
  } = property;

  return (
    <>
      <TextField className={descriptionText}>{summary}</TextField>
      <DescriptionSection title={t('The Space')} value={space} />
      <DescriptionSection title={t('Guest Access')} value={access} />
      <DescriptionSection title={t('Neighborhood')} value={neighborhood} />
      <DescriptionSection title={t('Interaction')} value={interactionWithGuests} />
      <DescriptionSection title={t('Other things to note')} value={notes} />
      <DescriptionSection title={t('Getting Around')} value={transit} />
    </>
  );
};

export default Details;