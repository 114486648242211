import createStyles from "@guestyci/foundation/createStyles";
import Icon from '@guestyci/foundation/Icon';

import { ReactComponent as NotFound } from 'assets/icons/not_found.svg';

const useStyles = createStyles(() => ({
  root: {
    width: 160,
    height: 171,
    position: 'relative',
  },
  line: {
    width: 160,
    height: 4,
    backgroundColor: '#5FA2F6',
    borderRadius: 4,
    position: 'absolute',
    bottom: 0,
  },
  pointContainer: {
    position: 'relative',
    left: 19,
    animationFillMode: 'forwards',
    animationDuration: '0.3s',
    animationTimingFunction: 'ease',
    animationName: '$dropAnimation'
  },
  pointSvg: {
    animationDelay: '0.23s',
    animationDuration: '0.8s',
    animationName: '$compressPoint',
    animationTimingFunction: 'cubic-bezier(0, 0, 0.1, 1.0)',
    transformOrigin: 'bottom',
  },
  point: {
    position: 'absolute',
    left: 0,
    animationDelay: '0.8s',
    animationName: '$rotateAnimation',
    animationDuration: '0.2s',
    animationTimingFunction: 'ease-in-out',
    transformOrigin: '0px 140px',
    animationFillMode: 'forwards',
  },
  pointRotate: {
    position: 'relative',
    animationDelay: '0.9s',
    animationName: '$rotateSecondAnimation',
    animationDuration: '0.3s',
    animationTimingFunction: 'ease-in',
    transformOrigin: '0px 140px',
  },
  '@keyframes dropAnimation': {
    '0%': {
      bottom: 300,
    },
    '100%': {
      bottom: -23,
    }
  },
  '@keyframes compressPoint': {
    '0%': {
      transform: 'none',
    },
    '10%': {
      transform: 'scale(1, 0.5)',
    },
    '100%': {
      transform: 'none',
    },
  },
  '@keyframes rotateAnimation': {
    '0%': {
      left: 0,
      transform: 'rotate(0deg) translateX(0px) translateY(-10px)',
    },
    '100%': {
      left: 40,
      transform: 'rotate(90deg) translateX(-120px) translateY(-10px)',
    }
  },
  '@keyframes rotateSecondAnimation': {
    '0%': {
      transform: 'none',
    },
    '50%': {
      transform: 'rotate(15deg) translateX(0px) translateY(-40px)',
    },
    '100%': {
      transform: 'none',
    }
  }
}));

const NoPropertiesFoundIcon = () => {
  const {
    root,
    line,
    pointContainer,
    pointSvg,
    point,
    pointRotate
  } = useStyles();

  return (
    <div className={root}>
      <div className={pointContainer}>
        <div className={pointRotate}>
          <div className={point}>
            <Icon
              className={pointSvg}
              width={122}
              height={143}
              fillColor="none"
              svg={NotFound}
            />
          </div>
        </div>
      </div>
      <div className={line} />
    </div>
  );
}
export default NoPropertiesFoundIcon;
