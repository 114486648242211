import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import createStyles from '@guestyci/foundation/createStyles';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import t from '@guestyci/localize/t.macro';

import Breadcrumbs from 'components/Breadcrumbs';
import ContactForm from 'components/ContactForm';
import FeaturedListings from 'components/FeaturedListings';
import BookNowGroupReserations from 'components/BookNow/BookNowGroupReservations';
import BookNow from 'components/BookNow';
import useGetListingById from 'hooks/useGetListingById';
import useDio from 'hooks/useDio';
import useSearchValues from 'hooks/useSearchValues';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';
import useIsGroupReservation from 'hooks/useIsGroupReservation';
import { BED_ARRANGEMENT, HOUSE_RULES } from 'constants/featureToggleNames';
import { useAnalytics } from 'analytics/hooks';
import FullPageError from 'components/FullPageError';
import Slider from 'components/PropertyPageParts/Slider';
import Reviews from 'components/PropertyPageParts/Reviews';
import Description from 'components/PropertyPageParts/Description';
import FullPageLoader from 'components/FullPageLoader';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  contentWrapper: {
    position: 'relative',
    background: '#FAFAFA',
    maxWidth: 1500,
    width: '100%',
    borderRadius: 20,
    [create('xs')]: {
      padding: '20px 10px 30px',
    },
    [create('lg')]: {
      padding: '60px 30px 60px',
    },
  },
  propertyDescriptionWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [create('xs')]: {
      flexDirection: 'column-reverse',
    },
    [create('md')]: {
      flexDirection: 'row',
    },
  },
  root: {
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  suggestedSectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1203,
    width: '100%',
    borderTop: '1px solid #D4D8E1',
    marginTop: 80,
    paddingTop: 50,
    [create('xs')]: {
      marginTop: 40,
      padding: '25px 20px 40px',
      overflow: 'hidden',
    },
    [create('md')]: {
      overflow: 'initial',
    },
  },
  contactsWrapper: {
    background: '#F5F5F5',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    [create('xs')]: {
      padding: '20px 10px',
    },
  },
}));

const BookWidget = ({ property }) => {
  const { isGroupReservation, isGroupReservationEnabled, isLoading } = useIsGroupReservation();
  if (isLoading) {
    return <FullPageLoader />;
  }

  if (isGroupReservationEnabled) {
    return <BookNowGroupReserations property={property} isGroupReservation={isGroupReservation} />;
  }
  return <BookNow property={property} />;
};

const PropertyPage = () => {
  const { id } = useParams();
  const [isAnalyticsSent, setIsAnalyticsSent] = useState(false);
  const { getPathWithLocale, locale } = useGetPathToNavigate();
  const history = useHistory();
  const { checkInDateLocalized, checkOutDateLocalized, pointofsale, minOccupancy } = useSearchValues();
  const { gtagEcommerceViewItem, fbtrack } = useAnalytics();
  //  see segment split DISCO-house-rules-and-bed-arrangements
  const [, isBedArrangementEnabled] = useFeatureToggle(BED_ARRANGEMENT);
  const [, isHouseRulesEnabled] = useFeatureToggle(HOUSE_RULES);
  const { property, isGettingListing, isGettingPropertyError, error } = useGetListingById({
    id,
    params: {
      fields: `${isBedArrangementEnabled ? 'bedArrangements' : ''} ${isHouseRulesEnabled ? 'unitTypeHouseRules' : ''}`,
      lang: locale,
    },
  });

  const {
    root,
    contentWrapper,
    propertyDescriptionWrapper,
    suggestedSectionWrapper,
    contactsWrapper,
  } = useStyles();

  const { dioTrack } = useDio();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!property) return;
    const {
      _id,
      nickname,
      title,
      prices: { currency, basePrice },
      parentId = null,
      type,
    } = property;

    if (type === 'MTL_CHILD' && parentId) {
      history.push({
        pathname: getPathWithLocale(`/properties/${parentId}`),
        search: history.location.search,
        state: history.location.state,
      });
    }

    dioTrack('view_selected_item', 'pageview', {
      listings: [
        {
          listing_id: _id,
          listing_nickname: nickname,
          item_title: title,
          currency,
          price: basePrice,
          quantity: 1,
        },
      ],
    });

    if (checkInDateLocalized && checkOutDateLocalized && minOccupancy && !isAnalyticsSent) {
      setIsAnalyticsSent(true);
      gtagEcommerceViewItem({
        listingId: property._id,
        listingName: property.title,
        totalPrice: property.prices.basePrice,
        currency: property.prices.currency,
        checkInDate: checkInDateLocalized || null,
        checkOutDate: checkOutDateLocalized || null,
        numberOfGuests: minOccupancy || null,
        pointOfSale: pointofsale,
      });
      fbtrack('ViewContent', {
        listing_id: [property._id],
        content_name: property.title,
        content_type: property.propertyType,
        currency: property.prices.currency,
        value: property.prices.basePrice,
        num_guests: minOccupancy,
        check_in_date: checkInDateLocalized,
        check_out_date: checkOutDateLocalized,
        point_of_sale: pointofsale,
      });
    }
  }, [
    checkInDateLocalized,
    checkOutDateLocalized,
    dioTrack,
    fbtrack,
    getPathWithLocale,
    gtagEcommerceViewItem,
    history,
    isAnalyticsSent,
    minOccupancy,
    pointofsale,
    property,
  ]);

  if (isGettingPropertyError) {
    return `${t('Error!')} ${error.message}`;
  }

  if (isGettingPropertyError) return <FullPageError />;
  if (isGettingListing) return <FullPageLoader />;

  return (
    <div className={root}>
      <Slider property={property} />
      <div className={contentWrapper}>
        <Breadcrumbs title={property.title} />
        <Reviews property={property} />
        <div className={propertyDescriptionWrapper}>
          <Description property={property} />
          <BookWidget property={property} />
        </div>
      </div>
      <div className={suggestedSectionWrapper}>
        <FeaturedListings context="property_page" />
      </div>
      <ContactForm wrapper={contactsWrapper} />
    </div>
  );
};

export default PropertyPage;
