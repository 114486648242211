import { Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField/TextField';
import createStyles from '@guestyci/foundation/createStyles';

import Icon from 'components/Icon';
import { useAmenities } from 'hooks/useConfigSettings';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 10,
    '& div': {
      textAlign: 'center',
    },
    [create('xs')]: {
      width: '33%',
    },
    [create('md')]: {
      width: '25%',
    },
  },
}));

const Amenities = ({ amenities, showAll }) => {
  const { root } = useStyles();
  const { allAmenityTypes: mappedAmenities } = useAmenities();

  const allAmenities = Object.keys(mappedAmenities).map((key) => ({
    ...mappedAmenities[key],
  }));

  const amenitiesLowerCase = amenities?.filter(Boolean).map((x) => x?.toLowerCase());
  let amenitiesPool = allAmenities?.filter((a) => amenitiesLowerCase?.includes(a?.lowerCase));
  if (!showAll) {
    amenitiesPool = amenitiesPool?.slice(0, 8);
  }

  return amenitiesPool.map(({ icon, label }) => (
    <Row align="center" className={root} key={`${icon}-${label}}`}>
      <Icon icon={icon} size={40} />
      <TextField>{label}</TextField>
    </Row>
  ));
};

export default Amenities;
