import { useQuery } from 'react-query';

import getListingById from 'api/listings/getListingById';
import { QUERY_IDS } from 'api';
import useGetPathToNavigate from './useGetPathToNavigate';

const defaultFields = [
  '_id',
  'title',
  'nickname',
  'type',
  'roomType',
  'propertyType',
  'accommodates',
  'amenities',
  'bathrooms',
  'bedrooms',
  'beds',
  'bedType',
  'timezone',
  'defaultCheckInTime',
  'defaultCheckOutTime',
  'address',
  'picture',
  'pictures',
  'prices',
  'publicDescription',
  'terms',
  'taxes',
  'reviews',
  'tags',
  'parentId',
];
const { GET_LISTING } = QUERY_IDS;

const useGetListingById = ({ id, enabled = true, params = {} } = {}) => {
  const { locale, isTranslationsEnabled } = useGetPathToNavigate();

  const transformedParams = { ...params };

  // eslint-disable-next-line no-nested-ternary
  const localeForSearch = isTranslationsEnabled ? locale === 'en' ? undefined : locale : undefined;

  const queryKey = isTranslationsEnabled ? [GET_LISTING, localeForSearch, id + defaultFields.join(' ') + transformedParams?.fields ?? ''] : [GET_LISTING, id + defaultFields.join(' ') + transformedParams?.fields ?? ''];

  const { data, isLoading, isError, error, isSuccess } = useQuery(
    queryKey,
    () =>
      getListingById({
        id,
        params: {
          ...transformedParams,
          lang: localeForSearch,
          fields: `${defaultFields.join(' ')} ${transformedParams.fields ?? ''}`,
        },
      }),
    {
      refetchOnWindowFocus: false,
      enabled,
    }
  );

  return {
    property: data,
    isGettingListing: isLoading,
    isGettingPropertyError: isError,
    isSuccess,
    error,
  };
};

export default useGetListingById;
