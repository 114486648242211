import { useContext, useEffect } from 'react';

import createStyles from '@guestyci/foundation/createStyles';
import { Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro/t.macro';

import useDio from 'hooks/useDio';
import useSearchValues from 'hooks/useSearchValues';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import Icon from 'components/Icon';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    width: '100%',
    maxWidth: 1095,
    height: 'auto',
    marginBottom: 20,
    display: 'flex',
    background: '#eef9fb',
    padding: 15,
    flex: '0 0 214px',
    [create('xs')]: {
      flexDirection: 'column',
      flex: '0 0 auto',
    },
    [create('xl')]: {
      flexDirection: 'row',
      flex: ({ shouldShowMapOnSearch }) => (shouldShowMapOnSearch ? '0 0 230px' : '0 0 917px'),
    },
  },
  description: {
    marginLeft: 20,
  },
}));

const NoResults = () => {
  const {
    displayOptions: { shouldShowMapOnSearch = false },
  } = useContext(WebsiteSettingsContext);
  const { root, description } = useStyles({ shouldShowMapOnSearch });
  const {
    checkInDateLocalized,
    checkOutDateLocalized,
    minOccupancy,
    city,
    country,
    propertyType,
    tags,
    includeAmenities,
    numberOfBedrooms,
    numberOfBathrooms,
    sortBy,
    sortOrder,
  } = useSearchValues();
  const { dioTrack } = useDio();

  useEffect(() => {
    dioTrack('search_no_results', 'side-effect', {
      checkIn: checkInDateLocalized,
      checkOut: checkOutDateLocalized,
      minOccupancy,
      city,
      country,
      propertyType,
      tags,
      includeAmenities,
      numberOfBedrooms,
      numberOfBathrooms,
      sortBy,
      sortOrder,
    });
  }, [
    checkInDateLocalized,
    checkOutDateLocalized,
    city,
    country,
    dioTrack,
    tags,
    includeAmenities,
    minOccupancy,
    numberOfBedrooms,
    numberOfBathrooms,
    propertyType,
    sortBy,
    sortOrder,
  ]);
  return (
    <Row align="center" className={root}>
      <Icon icon="no_results" size={200} />
      <Col className={description}>
        <TextField variant="h2" className="pb-2">
          {t("Sorry, we couldn't find any matching results")}
        </TextField>
        <TextField color="secondary">
          {t('Try changing some of the filter settings and broaden your search.')} 
          {' '}
          <br />
          {t('Or consider the similar properties below that we found for you.')}
        </TextField>
      </Col>
    </Row>
  );
};

export default NoResults;
