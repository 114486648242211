import { Link } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import { Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField/TextField';
import Icon from '@guestyci/foundation/Icon/Icon';
import { ReactComponent as BtnRight } from '@guestyci/icons/BtnRight.svg';
import createStyles from '@guestyci/foundation/createStyles';

import { shortLanguageList } from 'constants/languages';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    flexWrap: 'wrap',
    '& > *': {
      [create('xs')]: {
        fontSize: 14,
      },
      [create('lg')]: {
        fontSize: 12,
      },
    },
    [create('xs')]: {
      padding: '10px 0 20px',
    },
    [create('lg')]: {
      padding: 0,
    },
  },
  home: {
    cursor: 'pointer',
  },
  arrow: {
    fill: '#BDBDBD',
  },
  contentWrapper: {
    gap: 10,
  },
}));

// TODO: Add feature toggle
const routes = [
  { path: '/properties', breadcrumb: 'Home' },
  { path: '/properties/:id', breadcrumb: 'Property' },
  { path: '/properties/:id/checkout', breadcrumb: 'Payment' },
  { path: '/:locale/properties', breadcrumb: 'Home' },
  { path: '/:locale/properties/:id', breadcrumb: 'Property' },
  { path: '/:locale/properties/:id/checkout', breadcrumb: 'Payment' },
];

// TODO: find better way to get property name of the breadcrumb
const Breadcrumbs = ({ breadcrumbs, title }) => {
  const { arrow, root, contentWrapper } = useStyles();
  const renderElement = ({ breadcrumb, match, location }) => {
    if (match?.path === '/:locale/properties/:id' || match?.path === '/properties/:id') {
      return location?.state?.property?.title || title;
    }
    return breadcrumb;
  };
  return (
    <Row align="center" spacing="2" className={root}>
      {breadcrumbs.map(({ match, breadcrumb, location }, index) => (
        <Row className={contentWrapper} align="center" key={match.url}>
          {!!index && <Icon svg={BtnRight} height={12} width={7} className={arrow} />}
          <Link key={match.url} to={match.url + location.search}>
            <TextField color="secondary">{renderElement({ breadcrumb, match, location })}</TextField>
          </Link>
        </Row>
      ))}
    </Row>
  );
};

export default withBreadcrumbs(routes, { excludePaths: ['/', `/:locale(${shortLanguageList.join('|')})`] })(
  Breadcrumbs
);
