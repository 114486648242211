import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';


import { QUERY_IDS } from 'api';
import getPaymentSchedule from 'api/payment/getPaymentSchedule';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import { DISCO_BE_AUTO_PAYMENT } from 'constants/featureToggleNames';
import useSearchValues from './useSearchValues';


const { GET_PAYMENT_SCHEDULE } = QUERY_IDS;

const useGetPaymentSchedule = (params = {}) => {
  const [, isAutoPayment] = useFeatureToggle(DISCO_BE_AUTO_PAYMENT);

  const { total } = params;
  const { bookingType } = useContext(WebsiteSettingsContext);
  const { id: listingId } = useParams();
  const { checkInDateLocalized: checkIn, checkOutDateLocalized: checkOut } = useSearchValues();

  const { data, error, isLoading } = useQuery(
    [GET_PAYMENT_SCHEDULE, listingId, checkIn, checkOut, total, bookingType],
    () => getPaymentSchedule({ listingId, checkIn, checkOut, total, bookingType }),
    {
      refetchOnWindowFocus: false,
      enabled: isAutoPayment,
    }
  );

  return {
    data,
    error,
    isLoading,
  };
};

export default useGetPaymentSchedule;