import { useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';

import createInstantReservation from 'api/quote/createInstantReservation';
import createInstantGroupReservation from 'api/quote/createInstantGroupReservation';
import { BANK_TRANSFER_PAYMENT_TYPE, CREDIT_CARD_PAYMENT_TYPE } from 'constants/constants';
import { createSingleReservationParams, createGroupReservationParams } from 'utils';
import useTrackCreateReservationSuccess from './useTrackCreateReservationSuccess';
import useTrackCreateReservationError from './useTrackCreateReservationError';
import useGetPathToNavigate from './useGetPathToNavigate';
import useIsGroupReservation from './useIsGroupReservation';
import useSearchValues from './useSearchValues';

const useCreateInstant = () => {
  return useMutation(createInstantReservation);
};

const useCreateInstantGroup = () => {
  return useMutation(createInstantGroupReservation);
};

const useSubmitInstantBankTransfer = ({ property, reservationTotalAmount }) => {
  const { getPathWithLocale, locale } = useGetPathToNavigate();
  const history = useHistory();
  const { rooms } = useSearchValues();
  const { state } = useLocation();
  const { isGroupReservation, isGroupReservationEnabled } = useIsGroupReservation();
  const { mutateAsync: handleCreateInstantReservation, isLoading: isCreatingInstant } = useCreateInstant();
  const { mutateAsync: handleCreateInstantGroupReservation, isLoading: isCreatingInstantGroupReservation } =
    useCreateInstantGroup();
  const { trackSuccess } = useTrackCreateReservationSuccess({
    property,
    provider: BANK_TRANSFER_PAYMENT_TYPE,
    type: 'instant',
    reservationTotalAmount,
  });
  const { trackError } = useTrackCreateReservationError({ property, provider: BANK_TRANSFER_PAYMENT_TYPE, type: 'instant' });

  const [reservationError, setReservationError] = useState(false);

  const successPath = getPathWithLocale('/instant-success');


  const isRequestingReservation = isCreatingInstant || isCreatingInstantGroupReservation;

  const submitInstantSingle = async (values) => {
    const paymentType = BANK_TRANSFER_PAYMENT_TYPE;
    try {
      let selectedRatePlanId;
      let selectedQuoteId;
      if (isGroupReservationEnabled) {
        selectedRatePlanId = state?.ratePlan?._id;
        selectedQuoteId = state?.quoteData?.quote[0]?._id;
      } else {
        selectedRatePlanId = state?.ratePlanId;
        selectedQuoteId = state?.quoteId;
      }
      const params = createSingleReservationParams(values, selectedRatePlanId, locale);
      const response = await handleCreateInstantReservation({
        quoteId: selectedQuoteId,
        params: {
          ...params,
          paymentType,
        },
      });

      trackSuccess({
        response,
        dioAdditionalData: { paymentType },
      });
      history.push({
        pathname: successPath,
        state: {
          paymentType,
        },
      });
    } catch (error) {
      setReservationError(error?.response?.data?.error?.message || true);
      trackError({
        error,
        additionalData: { paymentType },
      });
    }
  };
  const submitInstantGroup = async (values) => {
    const paymentType = CREDIT_CARD_PAYMENT_TYPE
    try {
      const ratePlanId = state?.ratePlan?._id;
      const quoteData = state?.quoteData;
      const params = createGroupReservationParams({ values, rooms, quoteData, ratePlanId, locale });
      const response = await handleCreateInstantGroupReservation({
        params: {
          ...params,
          paymentType,
        },
      });

      trackSuccess({
        response,
        dioAdditionalData: { paymentType },
      });
      history.push({
        pathname: successPath,
        state: {
          paymentType,
        },
      });
    } catch (error) {
      setReservationError(error?.response?.data?.error?.message || true);
      trackError({
        error,
        additionalData: { paymentType },
      });
    }
  };

  const submitHandler = isGroupReservation ? submitInstantGroup : submitInstantSingle;
  return {
    isRequestingReservation,
    submitHandler,
    setReservationError,
    reservationError,
  };
};

export default useSubmitInstantBankTransfer;
