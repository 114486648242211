import parse from 'html-react-parser';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';

const useStyles = createStyles(() => ({
  root: {
    whiteSpace: 'pre-wrap',
    marginTop: 15,
  }
}));

const DescriptionSection = ({ title, value }) => {
  const { root } = useStyles();
  if (!value) return null;

  return (
    <div className={root}>
      <TextField bold>{title}</TextField>
      <TextField>{parse(value)}</TextField>
    </div>
  );
};

export default DescriptionSection;