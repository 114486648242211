import { useLocation } from 'react-router-dom';

import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { GROUP_RESERVATIONS } from 'constants/featureToggleNames';
import { useAnalytics } from 'analytics/hooks';
import useSearchValues from './useSearchValues';
import useDio from './useDio';
import useCalculateHostPayout from './useCalculateHostPayout';

const useTrackCreateReservationSuccess = ({ property, provider = null, type, reservationTotalAmount }) => {
  const { dioTrack } = useDio();
  const { gtagEcommercePurchase, reservationTypes, fbtrack } = useAnalytics();
  const hostPayout = useCalculateHostPayout();
  const { minOccupancy, pointofsale } = useSearchValues();
  const { state: { ratePlan: { money = {} } = {} } = {} } = useLocation();
  const [, isGroupReservationEnabled] = useFeatureToggle(GROUP_RESERVATIONS);

  const { totalTaxes } = money;

  const trackSuccess = ({ response, dioAdditionalData }) => {
    const { _id } = response;
    dioTrack('booking_create_success', 'side-effect', {
      reservationId: _id,
      type,
      ...(provider && { provider }),
      ...dioAdditionalData,
      listings: [
        {
          guests: minOccupancy,
          listing_id: property._id,
          listing_nickname: property.nickname,
          item_title: property.title,
          currency: property.prices.currency,
          price: property.prices.basePrice,
        },
      ],
    });

    if (isGroupReservationEnabled && response.reservations?.length) {
      response.reservations.forEach((reservation) => {
        gtagEcommercePurchase({
          reservationId: reservation._id,
          totalPrice: reservationTotalAmount || hostPayout,
          listingId: property._id,
          listingName: property.title,
          reservationType: reservationTypes.GROUP,
          groupReservationId: response._id,
          currency: property.prices.currency,
          taxes: totalTaxes,
          checkInDate: response.earliestCheckInDateLocalized,
          checkOutDate: response.latestCheckOutDateLocalized,
          numberOfGuests: reservation?.guestsCount || 1,
          pointOfSale: pointofsale,
        });
        fbtrack('Purchase', {
          listing_id: [property._id],
          content_name: property.title,
          content_type: property.propertyType,
          currency: property.prices.currency,
          reservationTotalAmount: reservationTotalAmount || hostPayout,
          num_guests: reservation?.guestsCount || 1,
          check_in_date: response.earliestCheckInDateLocalized,
          check_out_date: response.latestCheckOutDateLocalized,
          reservation_id: _id,
          point_of_sale: pointofsale,
        });
      });

      return;
    }
    gtagEcommercePurchase({
      reservationId: _id,
      totalPrice: reservationTotalAmount || hostPayout,
      listingId: property._id,
      listingName: property.title,
      reservationType: reservationTypes.SINGLE,
      currency: property.prices.currency,
      taxes: totalTaxes,
      checkInDate: response.checkInDateLocalized,
      checkOutDate: response.checkOutDateLocalized,
      numberOfGuests: response.guestsCount,
      pointOfSale: pointofsale,
    });
    fbtrack('Purchase', {
      listing_id: [property._id],
      content_name: property.title,
      content_type: property.propertyType,
      currency: property.prices.currency,
      reservationTotalAmount: reservationTotalAmount || hostPayout,
      num_guests: response.guestsCount,
      check_in_date: response.checkInDateLocalized,
      check_out_date: response.checkOutDateLocalized,
      reservation_id: _id,
      point_of_sale: pointofsale,
    });
  };

  return {
    trackSuccess,
  };
};

export default useTrackCreateReservationSuccess;
