import { useContext, useState, useMemo } from 'react';
import cn from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import { useMutation } from 'react-query';

import { Row } from '@guestyci/foundation/Layout';
import { FormProvider } from '@guestyci/foundation/enums';
import Form from '@guestyci/foundation/Form';
import Input from '@guestyci/foundation/Input';
import EmailInput from '@guestyci/foundation/EmailInput';
import FormField from '@guestyci/foundation/FormField';
import TextArea from '@guestyci/foundation/TextArea';
import { email } from '@guestyci/foundation/validators';
import createStyles from '@guestyci/foundation/createStyles';
import Spinner from '@guestyci/foundation/Spinner';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro/t.macro';

import ContactFormSuccess from 'components/ContactFormSuccess/ContactFormSuccess';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import contactUs from 'api/contact/contactUs';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 640,
    '& [class*="Input-root"]': {
      background: '#fff',
    },
  },
  inputGroup: {
    justifyContent: 'space-between',
    marginBottom: 30,
    '& div': {
      width: '100%',
      [create('xs')]: {
        maxWidth: '100%',
      },
      [create('lg')]: {
        maxWidth: 305,
      },
    },
    [create('xs')]: {
      flexDirection: 'column',
      marginBottom: 0,
      '& > div': {
        marginBottom: 30,
      },
    },
    [create('lg')]: {
      flexDirection: 'row',
    },
  },
  subject: {
    marginBottom: 30,
  },
  message: {
    marginBottom: 30,
  },
  title: {
    alignSelf: 'center',
    marginBottom: 30,
  },
  submitButton: {
    alignSelf: 'flex-end',
    width: '100%',
    maxWidth: 103,
    fontSize: 18,
    fontWeight: '500',
  },
  textArea: {
    '& textarea': {
      resize: 'none',
      minHeight: 193,
    },
  },
  loaderWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#f5f5f5b0',
  },
}));

const useContactUs = () => useMutation(contactUs);

const ContactForm = ({ wrapper }) => {
  const { root, formRoot, submitButton, inputGroup, subject, message, title, textArea, loaderWrapper } = useStyles();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const {
    displayOptions: { shouldShowContactUsForm },
    contentConfiguration: { sections },
  } = useContext(WebsiteSettingsContext);
  const renderContactForm = useMemo(() => !!sections?.CONTACT?.active, [sections]);

  const { mutate: handleSubmitContactUs, isLoading: isSubmittingContactUs } = useContactUs();

  const onSubmit = (values) => {
    handleSubmitContactUs(
      {
        params: values,
      },
      {
        onSuccess: () => setIsSuccessModalOpen(true),
      }
    );
  };

  if (!shouldShowContactUsForm && !renderContactForm) {
    return null;
  }

  return (
    <div className={wrapper}>
      <div className={root}>
        {!isSuccessModalOpen && (
          <FinalForm
            onSubmit={onSubmit}
            initialValues={{}}
            provider={FormProvider.Final}
            render={({ handleSubmit }) => (
              <Form
                title="contacts"
                onSubmit={handleSubmit}
                provider={FormProvider.Final}
                fieldInstance={Field}
                className={formRoot}
              >
                <TextField className={title} variant="h2">
                  {t('Contact us')}
                </TextField>
                <Row className={inputGroup}>
                  <FormField name="name" label={t('Name')} required>
                    <Input />
                  </FormField>
                  <FormField name="email" label={t('Email')} validate={[email]} required>
                    <EmailInput />
                  </FormField>
                </Row>
                <FormField name="subject" label={t('Subject')} required className={subject}>
                  <Input />
                </FormField>
                <FormField name="message" label={t('Message')} className={cn(message, textArea)} required>
                  <TextArea minRows={7} maxRows={7} />
                </FormField>
                <button type="submit" className={cn('btn btn-colored', submitButton)}>
                  {t('Submit')}
                </button>
              </Form>
            )}
          />
        )}
        {isSubmittingContactUs && (
          <div className={loaderWrapper}>
            <Spinner />
          </div>
        )}
        {isSuccessModalOpen && <ContactFormSuccess onClose={() => setIsSuccessModalOpen(false)} />}
      </div>
    </div>
  );
};

export default ContactForm;
