import Dialog, { DialogContent } from '@guestyci/foundation/Dialog';
import { Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro/t.macro';
import createStyles from '@guestyci/foundation/createStyles';
import Spinner from '@guestyci/foundation/Spinner';

import zeroAmountNotificationImg from 'assets/baners/zeroAmountNotification.svg';

const useStyles = createStyles(({ breakpoints: { create }, palette }) => ({
  imageContainer: {
    backgroundColor: palette.action.selected,
    width: '100%',
    maxWidth: 420,
    padding: '10px 22px',

    [create('sm')]: {
      padding: '20px 44px',
    },
  },
  image: {
    width: '100%',
  },
  spinner: {
    width: 30,
    height: 30,
  },
  dialog: {
    maxWidth: 480,
    minWidth: 320,
  },
  dialogContent: {
    padding: '30px 20px',
    [create('sm')]: {
      padding: 30,
    },
  },
}));

const ZeroAmountNotificationDialog = ({ open }) => {
  const { imageContainer, image, spinner, dialog, dialogContent } = useStyles();

  return (
    <Dialog open={open} noClear className={dialog}>
      <DialogContent className={dialogContent}>
        <Col spacing="4">
          <div className={imageContainer}>
            <img src={zeroAmountNotificationImg} alt="zeroAmountNotification" className={image} />
          </div>
          <Col spacing="2">
            <Row spacing="2" align="center">
              <Spinner className={spinner} />
              <TextField variant="h2" color="default">
                {t('Initiating card authentication')}
              </TextField>
            </Row>
            <TextField variant="h4" color="default">
              {t(`You'll be asked to approve a <b>zero-amount charge</b> as part of the authentication process.`, {
                b: (text) => <b>{text}</b>,
              })}
            </TextField>
          </Col>
        </Col>
      </DialogContent>
    </Dialog>
  );
};

export default ZeroAmountNotificationDialog;
