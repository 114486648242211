import Section from '@guestyci/foundation-legacy/Section';
import GenericError from '@guestyci/foundation-legacy/GenericError';

const FullPageError = () => {
  return (
    <Section style={{ height: '100vh' }} className="w-fill" bgColor="white" col>
      <GenericError className="text-gray-dark" />
    </Section>
  )
};

export default FullPageError;

