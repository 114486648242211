import { useMutation, useQuery } from 'react-query';

import createGroupQuote from 'api/quote/createGroupQuote';
import { QUERY_IDS } from 'api';

const { CREATE_GROUP_QUOTE } = QUERY_IDS;

export const useCreateGroupQuoteQuery = ({ params, enabled }) => {
  return useQuery([CREATE_GROUP_QUOTE, params], () => createGroupQuote({ params }), {
    enabled,
  });
};

const useCreateGroupQuote = ({ params, onError = () => {}, onSuccess = () => {} }) => {
  return useMutation(() => createGroupQuote({ params }), {
    mutationKey: [CREATE_GROUP_QUOTE, params],
    onError,
    onSuccess,
  });
};

export default useCreateGroupQuote;
