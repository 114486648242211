import createStyles from '@guestyci/foundation/createStyles';
// import defaultTheme from '@guestyci/foundation/theme/defaultTheme';

import Carousel from 'components/Carousel';

const useStyles = createStyles(({
  breakpoints: { create }
}) => ({
  root: {
    padding: '20px 0px 20px',
    width: '100%',
    [create('xs')]: {
      paddingTop: 0,
      paddingBottom: 20,
    },
  },
}));

const Slider = ({ property = {} }) => {
  const { root } = useStyles();
  const { pictures = [] } = property;

  if (!pictures || !pictures.length) {
    return null;
  }

  return (
    <div data-property-slider className={root}>
      <Carousel images={pictures} />
    </div>
  );
}

export default Slider;